import {
  RocketLaunchIcon,
  UserCircleIcon,
  UserGroupIcon,
  ShieldCheckIcon,
  LightBulbIcon,
  FlagIcon,
  ComputerDesktopIcon,
  LockOpenIcon,
  EyeSlashIcon,
  ShareIcon,
  GlobeAmericasIcon,
  BuildingOffice2Icon,
  ArrowsRightLeftIcon,
  NewspaperIcon,
  HandThumbUpIcon,
  PlayCircleIcon,
  MapIcon,
  GiftIcon,
  SparklesIcon,
  UserIcon,
  CogIcon,
  PencilSquareIcon,
  FolderIcon,
  StarIcon,
  BriefcaseIcon,
  ClockIcon,
  CurrencyDollarIcon,
  CalculatorIcon,
  RectangleGroupIcon,
  BoltIcon,
} from "@heroicons/vue/24/outline";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { defineStore, acceptHMRUpdate, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import * as allArticles from "@/assets/documentation/articles";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

export const useDocumentationStore = defineStore("documentation", () => {
  const layoutStore = useWorkspaceLayoutStore();
  const { isDesktop } = storeToRefs(layoutStore);
  const router = useRouter();
  const route = useRoute();
  const routeName = computed(() => route.name);
  const query = computed(() => route.query);
  const queryArticleId = computed(() => _.get(query.value, "articleId"));
  const queryCategoryId = computed(() => _.get(query.value, "categoryId"));
  const querySectionName = computed(() => _.get(query.value, "sectionName"));
  const helpViews = computed(() => _.includes(route.name, "Help"));
  const easyDataInputForm = computed(() =>
    _.includes(route.name, "DataEasyButton"),
  );
  const rawArticles = computed(() => {
    const mapped = _.map(allArticles, function (obj) {
      return obj;
    });
    return _.sortBy(mapped, ["order"]);
  });
  const articles = computed(() => {
    return rawArticles.value.map((article, index) => {
      return _.merge({}, article, { id: index + 1 });
    });
  });
  const topics = computed(() => {
    switch (routeName.value) {
      case "MainMap":
        return filterArticles([
          "What is Tower Hunt?",
          "Understand the Easy Button",
          "The role of the map",
          "Create a property",
          "Explored areas",
          "Search",
        ]);
      case "PropertyShell":
        return filterArticles([
          "What is a property?",
          "The role of the property diagram",
          "Visual features of the property diagram",
          "Understand the Easy Button",
          "Property diagram interactions",
          "Unlock datapoints",
          "Getting started for Tower Hunt contributors",
        ]);
      default:
        return filterArticles([
          "Understand the Easy Button",
          "Understand properties",
          "Create a task",
          "Share datapoints",
          "Understand reputation",
          "Unlock datapoints",
        ]);
    }
  });
  const categories = computed(() => {
    return [
      {
        id: 1,
        name: "Getting started",
        description:
          "Everything you need to know to get started and get to work in Tower Hunt.",
        subcategories: [
          {
            name: "Intro to Tower Hunt",
            articles: subcategoryArticles("Intro to Tower Hunt"),
            icon: FlagIcon,
            iconColor: "text-indigo-700",
          },
          {
            name: "Getting started for Tower Hunt admins",
            articles: subcategoryArticles(
              "Getting started for Tower Hunt admins",
            ),
            icon: SparklesIcon,
            iconColor: "text-indigo-700",
          },
          {
            name: "Getting started for new users",
            articles: subcategoryArticles("Getting started for new users"),
            icon: SparklesIcon,
            iconColor: "text-indigo-700",
          },
          {
            name: "Navigating Tower Hunt",
            articles: subcategoryArticles("Navigating Tower Hunt"),
            icon: RectangleGroupIcon,
            iconColor: "text-emerald-500",
          },
          {
            name: "Review roles & permissions",
            articles: subcategoryArticles("Review roles & permissions"),
            icon: CogIcon,
            iconColor: "text-pink-700",
          },
        ],
        icon: RocketLaunchIcon,
        visible: true,
      },
      {
        id: 2,
        name: "Real estate features",
        description: "Learn how to answer real estate questions in Tower Hunt.",
        subcategories: [
          {
            name: "The map",
            articles: subcategoryArticles("The map"),
            icon: GlobeAmericasIcon,
            iconColor: "text-cyan-400",
          },
          {
            name: "Properties",
            articles: subcategoryArticles("Properties"),
            icon: BuildingOffice2Icon,
            iconColor: "text-slate-600",
          },
          {
            name: "Companies & contacts",
            articles: subcategoryArticles("Companies & contacts"),
            icon: BriefcaseIcon,
            iconColor: "text-yellow-600",
          },
          {
            name: "Investments",
            articles: subcategoryArticles("Investments"),
            icon: ArrowsRightLeftIcon,
            iconColor: "text-blue-500",
          },
          {
            name: "Analyze datapoints",
            articles: subcategoryArticles("Analyze datapoints"),
            icon: CalculatorIcon,
            iconColor: "text-violet-500",
          },
        ],
        icon: BuildingOffice2Icon,
        visible: true,
      },
      {
        id: 3,
        name: "Database & revenue",
        description:
          "Add, validate, and access Tower Hunt data. Discover a new income stream.",
        subcategories: [
          {
            name: "Intro to datapoints",
            articles: subcategoryArticles("Intro to datapoints"),
            icon: CogIcon,
            iconColor: "text-indigo-500",
          },
          {
            name: "Make database changes",
            articles: subcategoryArticles("Make database changes"),
            icon: PencilSquareIcon,
            iconColor: "text-red-500",
          },
          {
            name: "The Easy Button",
            articles: subcategoryArticles("The Easy Button"),
            icon: BoltIcon,
            iconColor: "text-amber-500",
          },
          {
            name: "Unlock & access datapoints",
            articles: subcategoryArticles("Unlock & access datapoints"),
            icon: LockOpenIcon,
            iconColor: "text-emerald-500",
          },
          {
            name: "Safezone",
            articles: subcategoryArticles("Safezone"),
            icon: EyeSlashIcon,
            iconColor: "text-yellow-600",
          },
          {
            name: "Share datapoints",
            articles: subcategoryArticles("Share datapoints"),
            icon: ShareIcon,
            iconColor: "text-orange-500",
          },
          {
            name: "Validate database changes",
            articles: subcategoryArticles("Validate database changes"),
            icon: ShieldCheckIcon,
            iconColor: "text-green-400",
          },
          {
            name: "Earn revenue",
            articles: subcategoryArticles("Earn revenue"),
            icon: CurrencyDollarIcon,
            iconColor: "text-blue-500",
          },
          {
            name: "Time travel",
            articles: subcategoryArticles("Time travel"),
            icon: ClockIcon,
            iconColor: "text-violet-500",
          },
          {
            name: "Build credibility",
            articles: subcategoryArticles("Build credibility"),
            icon: HandThumbUpIcon,
            iconColor: "text-sky-600",
          },
        ],
        icon: ShieldCheckIcon,
        visible: true,
      },
      {
        id: 4,
        name: "Your account",
        description:
          "Personalize your experience and manage your account and to get the most from Tower Hunt.",
        subcategories: [
          {
            name: "Manage your account",
            articles: subcategoryArticles("Manage your account"),
            icon: UserIcon,
            iconColor: "text-emerald-500",
          },
          {
            name: "News & Notifications",
            articles: subcategoryArticles("News & Notifications"),
            icon: NewspaperIcon,
            iconColor: "text-amber-500",
          },
          {
            name: "Tasks",
            articles: subcategoryArticles("Tasks"),
            icon: StarIcon,
            iconColor: "text-blue-400",
          },
          {
            name: "Notes & Files",
            articles: subcategoryArticles("Notes & Files"),
            icon: FolderIcon,
            iconColor: "text-slate-400",
          },
        ],
        icon: UserCircleIcon,
        visible: true,
      },
      {
        id: 5,
        name: "Teams",
        description:
          "Learn how to use teams to share data, costs, and earnings.",
        subcategories: [
          {
            name: "Manage teams",
            articles: subcategoryArticles("Manage teams"),
            icon: UserGroupIcon,
            iconColor: "text-emerald-500",
          },
        ],
        icon: UserGroupIcon,
        visible: true,
      },
      {
        id: 6,
        name: "Tutorials & videos",
        description:
          "Learning Tower Hunt make simple: tutorials, videos, and tips to get up to speed and get more done.",
        subcategories: [
          {
            name: "Tutorials",
            articles: subcategoryArticles("Tutorials"),
            icon: LightBulbIcon,
            iconColor: "text-indigo-500",
          },
          {
            name: "Videos",
            articles: subcategoryArticles("Videos"),
            icon: PlayCircleIcon,
            iconColor: "text-cyan-400",
          },
          {
            name: "Keyboard shortcuts & accessibility",
            articles: subcategoryArticles("Keyboard shortcuts & accessibility"),
            icon: ComputerDesktopIcon,
            iconColor: "text-gray-700",
          },
          {
            name: "Discover more",
            articles: subcategoryArticles("Discover more"),
            icon: MapIcon,
            iconColor: "text-emerald-500",
          },
        ],
        icon: LightBulbIcon,
        visible: true,
      },
      {
        id: 7,
        name: "Changelog",
        description:
          "If you're curious about what's new in Tower Hunt &mdash; and what's changed &mdash; you're in the right place.",
        subcategories: [
          {
            name: "Changelog",
            articles: subcategoryArticles("Changelog"),
            icon: GiftIcon,
            iconColor: "text-emerald-500",
          },
        ],
        icon: GiftIcon,
        visible: false,
      },
    ];
  });
  const visibleCategories = computed(() => {
    return categories.value.filter((categoryObj) => categoryObj.visible);
  });

  const helpQuery = ref("");
  const commonArticles = computed(() => {
    return filterArticles([
      "What is Tower Hunt?",
      "Keyboard shortcuts",
      "Using the map",
    ]);
  });
  const filteredArticles = computed(() =>
    helpQuery.value === ""
      ? []
      : articles.value.filter((article) => {
          const nameMatch = article.name
            .toLowerCase()
            .includes(helpQuery.value.toLowerCase());

          const keywordMatch = article.keywords
            ? article.keywords
                .toLowerCase()
                .includes(helpQuery.value.toLowerCase())
            : false;

          return nameMatch || keywordMatch;
        }),
  );

  function categoryFor(articleObj) {
    return _.find(categories.value, function (categoryObj) {
      return _.find(categoryObj.subcategories, {
        name: articleObj.subcategory,
      });
    });
  }
  function subcategoryArticles(name) {
    return articles.value.filter((article) => article.subcategory === name);
  }

  function filterArticles(names) {
    return articles.value.filter((article) => _.includes(names, article.name));
  }

  function viewArticle(articleObj, sectionName = undefined) {
    helpQuery.value = "";

    if (helpViews.value || easyDataInputForm.value || !isDesktop.value) {
      const sectionHash = sectionName
        ? { hash: `#${_.kebabCase(sectionName)}` }
        : {};
      router.push(
        _.merge(
          {},
          {
            name: "HelpArticle",
            params: { articleId: _.kebabCase(articleObj.name) },
          },
          sectionHash,
        ),
      );
    } else {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          horizontalTab: "Help",
          verticalTab: undefined,
          articleId: _.kebabCase(articleObj.name),
          categoryId: undefined,
          sectionName: sectionName ? _.kebabCase(sectionName) : undefined,
        },
      });
    }
  }
  function viewCategory(categoryObj) {
    if (helpViews.value) {
      router.push({
        name: "HelpCategories",
        params: { categoryId: categoryObj.id },
      });
    } else {
      router.push({
        name: route.name,
        query: {
          ...route.query,
          articleId: undefined,
          categoryId: categoryObj.id,
          sectionName: undefined,
        },
      });
    }
  }

  const helpCenterPath = computed(() => {
    if (helpViews.value) {
      return { name: "HelpCenter" };
    } else {
      let pathObject;

      if (queryArticleId.value) {
        pathObject = {
          name: "HelpArticle",
          params: { articleId: queryArticleId.value },
        };
      } else if (queryCategoryId.value) {
        pathObject = {
          name: "HelpCategories",
          params: { categoryId: queryCategoryId.value },
        };
      } else {
        pathObject = { name: "HelpCenter" };
      }

      if (querySectionName.value) {
        return _.merge({}, pathObject, {
          hash: `#${_.kebabCase(querySectionName.value)}`,
        });
      } else {
        return pathObject;
      }
    }
  });

  return {
    helpViews,
    helpQuery,
    helpCenterPath,
    queryArticleId,
    queryCategoryId,
    querySectionName,
    articles,
    commonArticles,
    filteredArticles,
    categories,
    visibleCategories,
    topics,
    viewArticle,
    viewCategory,
    categoryFor,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useDocumentationStore, import.meta.hot),
  );
}
