import { ref, computed } from "vue";
import { defineStore, acceptHMRUpdate } from "pinia";

export const useWorkspaceLayoutStore = defineStore("workspaceLayout", () => {
  const windowWidth = ref(0);
  const windowHeight = ref(0);
  const workspaceLayout = ref(null);
  const workspaceResized = ref(false);
  const isPortrait = computed(() => windowHeight.value > windowWidth.value);
  const isDesktop = computed(() => windowWidth.value >= 1024);
  function matchLayoutToScreen() {
    if (isPortrait.value) {
      workspaceLayout.value = "topAndBottom";
    } else {
      workspaceLayout.value = "sideBySide";
    }
  }

  return {
    windowHeight,
    windowWidth,
    workspaceLayout,
    workspaceResized,
    isDesktop,
    isPortrait,
    matchLayoutToScreen,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useWorkspaceLayoutStore, import.meta.hot)
  );
}
